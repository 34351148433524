import React from 'react'
import { Container, Button } from 'react-bootstrap'
// import Flotantes2 from './Flotantes2'

export default function Hero() {
  return (
    <>
    <Container className='  alturanav'></Container>
    <Container className='text-center w-75 mt-5 mb-3'>
        <h1 className='display-2'>Revitalizamos<br />tu negocio.</h1>
        <p className='display-6'>Brindamos un cuidado completo,<br />al estilo skincare</p>
<div className="centrado">
        <div className='cardeaprev6 cardsss'>
        <div className='cardea6 xonbor'>
        <p className=''>Especializados en el sector nupcial</p>
        </div>
        </div>
        </div>
    </Container>

 {/* <Flotantes2></Flotantes2> */}

 <Container className='text-center widero mt-4'>
 <Button href='/reservacita' className='pt-2 rounded-pill ms-4 me-4 mb-5 ps-5 pe-5 fsfs gradient bg-dark deconone boldd'>¡Reserva cita!</Button>
    </Container>
 </>








    

  )
}
