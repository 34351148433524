import React from 'react'
import { Container, Row, Col, Card } from "react-bootstrap";

// import book3 from '../assets/book3.png'
// import book2 from '../assets/book2.png';
// import book1 from '../assets/book1.png';
// import shelf from '../assets/shelf.png';
// import wand from '../assets/wand.png';
// import conv from '../assets/conv.png';
// import escuchar from '../assets/escuchar.png';
// import birdImg from '../assets/bird.svg'

import developI from '../assets/develop.webp'
import rebrandI from '../assets/rebranding.webp'
import multitudI from '../assets/multitud.webp'
import { Link } from 'react-router-dom';

export default function Carditas() {
  return (

    // AQUIARRIBA FOTOSVESTIDOS NO TAN MINIMAL NO 

    <Container className='mb-5 mt-3 animawap'>
      <h1 className="text-center marbocars ms-1 me-1">¿Qué hacemos en Coquette Agency?</h1>
      <Row className="justify-content-center align-items-center marcer">
       
          <Card className='mb-4 esca  lool2 bor cardss colorcard3 sinpad'>  
            <Card.Body className=''>


            <p className='text-center tit'>Rebranding</p>
                <p className='text-center subtit'>Refrescamos la imagen<br />de tu negocio.</p>
                <div className="centrado">
                <img src={rebrandI}  width='100px' alt="" className='flotante' />
                </div>
                <div className="centrado">
                <Link to='/rebranding' className='botocar sindecor'>Skincare</Link>
               </div>
            </Card.Body>
          </Card>
       
{/*      
          <Card className='mb-4 martarj lool2  esca bor cardss arri cardeaprev6 sinpad'>
            <Card.Body className='cardea6'>
            

            <p className='text-center tit'>Exposición</p>
                <p className='text-center subtit'>Posiciona tu negocio en "la Gran Vía" de la red.</p>
                {/* <p>estartegiaposicoanrtrafico</p> 
                <div className="centrado">
                <img src={multitudI}  width='100px' alt="" />
                </div>
                <div className="centrado">
                <button className='botocar'>Gran Vía</button>
               </div>
            </Card.Body>
          </Card> */}
       

       <Card className='mb-4 martarj lool2  esca bor cardss arri colorcard2 sinpad'>
            <Card.Body className=''>
            

            <p className='text-center tit'>Exposición</p>
                <p className='text-center subtit'>Posicionamos tu negocio<br />en "La Gran Vía" de la red.</p>
                {/* <p>estartegiaposicoanrtrafico</p> */}
                <div className="centrado">
                <img src={multitudI}  width='100px' alt="" className='flotante2'/>
                </div>
                <div className="centrado">
                <Link to='/exposicion' className='botocar sindecor'>La Gran Vía</Link>
               </div>
            </Card.Body>
          </Card>
      
          <Card className='mb-4  lool2 esca bor cardss colorcard'>
            <Card.Body>             
            <p className='text-center tit'>Desarrollo</p>
                <p className='text-center subtit'>Creamos soluciones<br />a medida.</p>
                {/* (software) */}
                <div className="centrado">
                <img src={developI}  width='100px' alt="" className='flotante' />
                </div>
                <div className="centrado">
                <Link to='/desarrollo' className='botocar sindecor'>Soluciones</Link>
               </div>
            </Card.Body>
          </Card>
       

{/*         
          <Card className='mb-4  esca lool2 bor cardss'>            
            <Card.Body>
            <p className='text-center tit'>Rebranding</p>
                <p className='text-center subtit'>Refrescamos la imagen de tu negocio.</p>
                <div className="centrado">
                <img src={birdImg}  width='100px' alt="" />
                </div>
                <div className="centrado">
                <button className='botocar'>Skincare</button>
               </div>
            </Card.Body>
          </Card>
     
        
      
          <Card className='mb-4 martarj lool2 esca bor cardss arri'>
            <Card.Body>
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center mb-1">
<div className="flex-icon">
<img className="mb-4 mx-2" src={book2} alt="" width={'65px'}/>
</div>
<h2 className="white boldd line-2 mb-3">Fomenta la Lectura</h2>
</div>
<h5 className="white line-2 ms-2">Despierta el amor por la lectura en tus hijos.</h5>
</div>
            </Card.Body>
          </Card>
      

       
          <Card className='mb-4  lool2 esca bor cardss'>
            <Card.Body>            
            <p className='text-center tit'>Rebranding</p>
                <p className='text-center subtit'>Refrescamos la imagen de tu negocio.</p>
                <div className="centrado">
                <img src={birdImg}  width='100px' alt="" />
                </div>
                <div className="centrado">
                <button className='botocar'>Skincare</button>
               </div>
            </Card.Body>
          </Card> */}
      
      </Row>
    </Container>
  )
}
