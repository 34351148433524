import React, { useEffect } from 'react'
import Navbarr from '../components/Navbarr';
import Footer from '../components/Footer';
import { Container, Card, Row } from "react-bootstrap";
// import dior from '../assets/rebrandLogo.png'
// import starbucks from '../assets/starbucks.png'
// import airbnb from '../assets/Airbnb.png'
// import loewe from '../assets/Loewe.png'
// import yvessaintlaurent from '../assets/YvesSaintLaurent.png'
// import instagram from '../assets/InstagramChg.png'


export default function Exposicion() {

  useEffect(() => {
    // Esta función se ejecuta después del montaje del componente
    window.scrollTo({
      top: 30,
      behavior: 'smooth', // Agrega un desplazamiento suave
    });
  }, []);

  return (
    <>
    <Navbarr></Navbarr>

    <Container className='  alturanav'></Container>
    <Container className='text-center w-75 mt-5 mb-3'>
        <h1 className='display-2'>PRESENCIA</h1>
        <p className='display-6 mb-5'>Posicionamiento para tu negocio.</p>
        <p className='fosi2 mb-5'>Después del "skincare" previo a salir,<br />es hora de exponerse en<br />"La Gran Vía" de internet, las redes sociales</p>
</Container>

<Container className='mb-5 mt-3 animawap'>
      <Row className="justify-content-center align-items-center marcer congap">

          <Card className='mb-4 esca  lool2 bor cardss22 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className=''>
     
         {/* <h1 className='text-center pt-4'>LOGO</h1> */}
         {/* <p className='text-center  ps-4 pe-4 boldd mt-3'>Después del "skincare" previo a salir, es hora de exponerse en "La Gran Vía" de internet, las redes sociales
</p> */}
    {/* <p className='text-center  ps-2 pe-2'>Adaptamos actualizamos tu logo a la nueva tendencia minimalista ó creamos un concepto nuevo</p> */}
    {/* <p className='text-center  ps-4 pe-4'>Actualizamos y adaptamos tu logo a la nueva tendencia minimalista</p> */}
    <p className='text-center  ps-4 pe-4 subr'>Ahora imagina:</p>

    <p className='ms-4 me-3'><i className="fas fa-location-dot me-2"></i>Decidir en qué zona salen tus anuncios (ciudad, país...)</p> 
    <p className='ms-4 me-3'><i className="fas fa-ring me-2"></i>Filtrar tus anuncios para que sólo se muestren a gente que esté cerca de casarse o que les interese las bodas, vestidos, sector nupcial...</p> 
    <p className='ms-4 me-3'><i className="fas fa-venus me-2"></i>Elegir a qué tipo de persona se muestran tus anuncios (sexo, rango de edad...)</p> 
    <p className='ms-4 me-3'><i className="fas fa-person-walking-arrow-loop-left me-2"></i>Volver a exponerse a gente que ya ha mostrado interés previamente, tanto en la web como en redes sociales (de manera automática)</p>
    <p className='ms-4 me-3'><i className="fas fa-people-line me-2"></i>Encontrar nuevos clientes potenciales basados en datos previos</p> 
   




    {/* <div class="logos">
      <div class="logos-slide2">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      </div>

      <div class="logos-slide2">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      </div>
    </div> */}

   

{/* <div class="logos">
      <div class="logos-slide">
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>

      <div class="logos-slide">
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>
    </div> */}




{/* lo de obtendras y la bandfa decirlo en discurso voz medjor lo quye viene dentro  */}
    {/* <p className='text-center  ps-4 pe-4 mt-3'>Obtendrás el logotipo, isotipo y demás en distintos formatos colores banda y en la calidad mas alta (posible svg)  y distintosfondos etc</p> */}
    {/* <p className='text-center  ps-2 pe-2'>Importancia logo es simbolo y es la cara de un negocio, metafora persona</p> */}

    {/* <p className='text-center  ps-4 pe-4 mt-3 pb-2'>El logotipo se entregará en la máxima calidad de imagen</p> */}

{/* equipo desarrollador diseñador grfico etc con años experiencia sector  */}
            </Card.Body>

          </Card>


          <Card className='mb-4 esca  lool2 bor cardss22 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className=''>
  
    <p className='text-center  ps-4 pe-4 subr'>Y también:</p>
    <p className='ms-4 me-3'><i className="fas fa-magnifying-glass me-2"></i>Aparecer al principio de los resultados de búsqueda en Google y redes sociales (orgánico y promocionado)</p>
    <p className='ms-4 me-3'><i className="fas fa-bag-shopping me-2"></i>Mostrarse a las personas en el momento más adecuado, cuando tienen la intención de compra, encontrar un negocio, un vestido, un servicio...</p>
    <p className='ms-4 me-3'><i className="fas fa-list me-2"></i>Posicionarte cuando alguien busque a tu competencia o términos relacionados con el sector nupcial, la localidad, etc...</p>
    <p className='ms-4 me-3'><i className="fas fa-comments me-2"></i>Crear una estrategia de contenido orgánico programado para aumentar la interación y exposición</p>

    <p className='ms-4 me-3'><i className="fas fa-star me-2"></i>Encontrar infuencers adecuados y cerrar campañas</p>





    {/* <p className='text-center  ps-2 pe-2 mt-3'>Es simple.<br />Destaca de tu competencia</p> */}


            </Card.Body>

          </Card>


          <p className='text-center fosi2'>Es simple.<br />Destaca de tu competencia</p>
  
      </Row>
    </Container>



<Footer></Footer>
    
    </>
  )
}
