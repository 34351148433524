
import React from 'react';
import '../App.css';

import logoImg from '../assets/CoquetteLogo.webp'
import { Container, Nav, Navbar, Button } from "react-bootstrap";

function Nave2() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-transparent fijoo woww fixed-top">
      <Container className=' w-100'>
        <Navbar.Brand href="/"> <img className='ms-4 pt-2' src={logoImg} alt="Coquette Agency" width="85" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='text-center fsfs'>
          <Nav className="me-auto">
            <Nav.Link className='boldd tieneback' href="/rebranding">Rebranding</Nav.Link>
            <Nav.Link className='boldd tieneback' href="/exposicion">Exposición</Nav.Link>
            <Nav.Link className='boldd tieneback' href="/desarrollo">Desarrollo</Nav.Link>
          </Nav>
          <Nav className=''>
            <Nav.Link className='tieneback boldd condefle' href="/login">Acceder</Nav.Link>
            <Button href='/reservacita' className='pt-2 rounded-pill ms-4 me-4 ps-5 pe-5 fsfs gradient bg-dark deconone boldd'>¡Reserva cita!</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Nave2;



// import React from 'react'
// import logoImg from '../assets/vlogoblack.png'

// export default function Navbarr() {
//   return (
//     <div className="barrasup">
//     <div className="barraizq">
//     <img src={logoImg} width='80px' alt="Vestidos de novia" />
//     </div>
  
//     <div className="barracen">
//     <img src={logoImg} width='80px' alt="Vestidos de novia" />
//     </div>
  
//     <div className="barradch">
//     <img src={logoImg} width='80px' alt="Vestidos de novia" />
//     </div>
//   </div>
//   )
// }
