import { Container } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import birdImg from './assets/bird.svg'
// import Cardss from './components/Cardss'

import Home from './components/Home';
import Rebranding from './components/Rebranding';
import Exposicion from './components/Exposicion';
import Desarrollo from './components/Desarrollo';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Legal from './components/Legal';
import Cookies from './components/Cookies';
import Login from './components/Login';
import ReservaCita from './components/ReservaCita';


function App() {
  return (
  // <div className='alturapredSINCLASE'>



  <>
{/* <Navbarr></Navbarr>
<Hero></Hero>
  <Carditas></Carditas>
  <Footer></Footer> */}
  {/* <Cardss></Cardss> */}

  <Router>
<Routes>
<Route exact path='/' Component={Home}/>
<Route exact path='/rebranding' Component={Rebranding}/>
<Route exact path='/exposicion' Component={Exposicion}/>
<Route exact path='/desarrollo' Component={Desarrollo}/>
<Route exact path='/terms' Component={Terms}/>
<Route exact path='/privacy' Component={Privacy}/>
<Route exact path='/legal' Component={Legal}/>
<Route exact path='/cookies' Component={Cookies}/>
<Route exact path='/login' Component={Login}/>
<Route exact path='/reservacita' Component={ReservaCita}/>
</Routes>
</Router>


    {/* <div className="caja ps-4">

      <div className="indice1 centradosin gap-3">
        <p className=' marce'>01 -</p>
        <p className='marce'>Qué hacemos</p>
      </div>

      <div className="indice1 centradosin gap-3">
        <p className=' marce'>02 -</p>
        <p className='marce'>Por qué</p>
      </div>

      <div className="indice1 centradosin gap-3">
        <p className=' marce'>03 -</p>
        <p className='marce'>Cómo</p>
      </div>

      <div className="indice1 centradosin gap-3">
        <p className=' marce'>04 -</p>
        <p className='marce'>Vamos <img src={birdImg} width='100px'></img></p>
      </div>

    </div> */}

  
  {/* </div> */}

</>
  );
}

export default App;
