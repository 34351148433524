import React, { useEffect } from 'react'
import Navbarr from '../components/Navbarr';
import Footer from '../components/Footer';
import { Container, Card, Row } from "react-bootstrap";
// import dior from '../assets/rebr.png'
// import starbucks from '../assets/starbucks.png'
// import airbnb from '../assets/Airbnb.png'
// import loewe from '../assets/Loewe.png'
// import yvessaintlaurent from '../assets/YvesSaintLaurent.png'
// import instagram from '../assets/InstagramChg.png'


export default function Desarrollo() {

  useEffect(() => {
    // Esta función se ejecuta después del montaje del componente
    window.scrollTo({
      top: 30,
      behavior: 'smooth', // Agrega un desplazamiento suave
    });
  }, []);
  return (
    <>
    <Navbarr></Navbarr>

    <Container className='  alturanav'></Container>
    <Container className='text-center w-75 mt-5 mb-3'>
        <h1 className='display-2'>SOLUCIONES</h1>
        <p className='display-6 mb-5'>Gestión para tu negocio.</p>
</Container>

<Container className='mb-5 mt-3 animawap'>
      <Row className="justify-content-center align-items-center marcer conelmar">

          <Card className='mb-4 esca  lool2 bor cardss2 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className=''>
     
         {/* <h1 className='text-center pt-4'>LOGO</h1> */}

         <div className="centrado">
                {/* <img src={rebrandI}  width='100px' alt="" className='flotante' /> */}
                <i className="fas fa-store tama flotante"></i>
                </div>
         <p className='text-center  ps-4 pe-4 boldd mt-3'>Creamos tu tienda online para que puedas exponer y vender tus productos o servicios<br /><br />Tus clientes pagarán mediante tu página web
</p>



{/* IA AHORRAS COSTE UYNA PERSONA RRHH AHI TODO EL RATO   VOZ PROPIA EN UN FUTURO SEWIER LLamada*/}

    {/* <p className='text-center  ps-4 pe-4 subr'>Ahora imagina:</p> */}

    {/* <p className='text-center  ps-2 pe-2 mt-3'>Es simple.<br />Destaca de tu competencia</p> */}

{/* lo de obtendras y la bandfa decirlo en discurso voz medjor lo quye viene dentro  */}

{/* equipo desarrollador diseñador grfico etc con años experiencia sector  */}
            </Card.Body>

          </Card>

          <Card className='mb-4 esca martarj lool2 bor cardss2 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className=''>
     
         {/* <h1 className='text-center pt-4'>LOGO</h1> */}
        
         <div className="centrado">
                {/* <img src={rebrandI}  width='100px' alt="" className='flotante' /> */}
                <i className="fas fa-people-roof tama flotante2"></i>
                </div>
<p className='text-center  ps-4 pe-4 boldd mt-3'>Creamos un software a medida para que puedas gestionar todos tus clientes<br /><br />Además tendrás la contabilidad a un click</p>



{/* IA AHORRAS COSTE UYNA PERSONA RRHH AHI TODO EL RATO   VOZ PROPIA EN UN FUTURO SEWIER LLamada*/}

    {/* <p className='text-center  ps-4 pe-4 subr'>Ahora imagina:</p> */}

    {/* <p className='text-center  ps-2 pe-2 mt-3'>Es simple.<br />Destaca de tu competencia</p> */}

{/* lo de obtendras y la bandfa decirlo en discurso voz medjor lo quye viene dentro  */}

{/* equipo desarrollador diseñador grfico etc con años experiencia sector  */}
            </Card.Body>

          </Card>


          <Card className='mb-4 esca  lool2 bor cardss2 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className=''>
     
         {/* <h1 className='text-center pt-4'>LOGO</h1> */}
      
         <div className="centrado">
                {/* <img src={rebrandI}  width='100px' alt="" className='flotante' /> */}
                <i className="fas fa-headset tama flotante"></i>
                </div>
<p className='text-center  ps-4 pe-4 boldd mt-3'>Desarrollamos un asistente de atención al cliente impulsado por IA (Inteligencia Artificial)<br /><br />
Entrenamos el modelo para que resuelva dudas al cliente, cierre ventas y redirija a la reserva de una cita, llamada, correo...<br /><br />Todo esto del mismo modo en el que lo harías tú personalmente
</p>

{/* IA AHORRAS COSTE UYNA PERSONA RRHH AHI TODO EL RATO   VOZ PROPIA EN UN FUTURO SEWIER LLamada*/}

    {/* <p className='text-center  ps-4 pe-4 subr'>Ahora imagina:</p> */}

    {/* <p className='text-center  ps-2 pe-2 mt-3'>Es simple.<br />Destaca de tu competencia</p> */}

{/* lo de obtendras y la bandfa decirlo en discurso voz medjor lo quye viene dentro  */}

{/* equipo desarrollador diseñador grfico etc con años experiencia sector  */}
            </Card.Body>

          </Card>

      
  
      </Row>
    </Container>



<Footer></Footer>
    
    </>
  )
}
