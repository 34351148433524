import React from 'react'
import { Container, Card } from "react-bootstrap";
import Nave2 from './Navbarr';
import Footer from './Footer';

export default function Legal

() {
  return (
   <>
   <Nave2></Nave2>
   <Container className='anteHero'></Container>
    <Container className='p-5'>
        <h1 className='text-center boldd mb-4'>Aviso Legal de Coquette Agency</h1>
        <Card className='lool borrr'><Card.Body className='p-5'>
        <h2>Introducción</h2>
<p>Este Aviso Legal regula el uso de esta página web. El acceso a la web es en general gratuito, salvo en aquello relativo al coste de la conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso contratada por las personas usuarias y de los servicios que de forma expresa la persona usuaria pueda contratar a través de la web.</p>
<br></br>
<h2>Aceptación de condiciones legales de navegación</h2>
<p>La utilización de la web https://coquette.agency atribuye la condición de persona usuaria e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal en el mismo momento en que la persona usuaria acceda a la web https://coquette.agency. En consecuencia, el usuario debe leer atentamente el presente Aviso Legal y por ello se pone a su disposición de forma permanente.</p>
<br></br>
<h2>Responsable - ¿Quién es el responsable legal de la web?</h2>
<p>
De conformidad con el artículo 10 de la Ley 34/2002 , de 11 de julio, de " Servicios de la Sociedad de la Información y Comercio Electrónico (LSSI)", se informa que la empresa encargada de la gestión y funcionamiento del sitio Web es:
</p><p>
Denominación comercial: COQUETTE.AGENCY
</p><p>
Nombre: COQUETTE AGENCY, SOCIEDAD LIMITADA
</p><p>
CIF: ''
</p><p>
Dirección: ''
</p><p>
Nombre del dominio: https://coquette.agency
</p><p>
Correo electrónico: hola@coquette.agency
</p><p>
Actividad: Software as a Service (SaaS). Servicios digitales con Inteligencia Artificial.
</p>

<br></br>
<h2>Normativa aplicable</h2>
<p>- Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos).</p>
<p>- Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
<p>- Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico</p>
<p>La web cumple y se compromete con el cumplimiento de esta normativa, sin perjuicio del deber de cumplimento de otras normativas específicas o sectoriales no mencionadas.</p>
<br></br>
<h2>Información sobre los vínculos “links”</h2>
<p>COQUETTE.AGENCY no se hace responsable de los contenidos de las webs de terceros a las que se puede acceder mediante vínculos "links" o de cualquier contenido puesto a disposición por terceros, aún más debido a que pueden ser modificados en cualquier momento por su gestor de contenidos y sin previo aviso.
Cualquier uso de un vínculo o acceso a una Web no propia es realizado por voluntad y riesgo exclusivo de la persona usuaria y COQUETTE.AGENCY no garantiza ninguna información obtenida a través del contenido al que se llega mediante un vínculo ajeno a la Web de https://coquette.agency, ni se responsabiliza de ninguna pérdida, reclamación o perjuicio derivada del uso o mal uso de un vínculo, o de la información obtenida a través de él, incluyendo otros vínculos a terceros o webs, de la interrupción en el servicio o en el acceso.</p>
<br></br>
<h2>Limitación de responsabilidades</h2>
<p>
COQUETTE.AGENCY hace todo lo posible para evitar cualquier error en relación con los contenidos de esta página web.
</p><p>
COQUETTE.AGENCY adopta las medidas y los mecanismos necesarios para garantizar la seguridad de las comunicaciones y las transacciones que se produzcan mediante el portal web, según el estado de la tecnología y de acuerdo con la tipología de las transacciones y los servicios que se ponen a disposición de las personas usuarias.
</p><p>
COQUETTE.AGENCY no será responsable de los daños que se puedan ocasionar al sistema informático de las personas usuarias por cualquier causa ajena o no imputable a la web https://coquette.agency, incluidos, sin limitaciones, los daños ocasionados a causa de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo del sistema electrónico.
</p><p>
Asimismo, COQUETTE.AGENCY tampoco responderá de ninguna interrupción, error o fallo que se produzca en el sistema, debido a un mal funcionamiento de la red o de los servidores que están conectados.
</p>

<br></br>
<h2>NO comparta su información personal</h2>
<p>https://coquette.agency nunca le enviará un correo electrónico ni le llamará por teléfono para pedirle que revele o verifique la contraseña de su cuenta bancaria en COQUETTE.AGENCY, ni sus números de cuenta bancaria o tarjeta de crédito, ni ningún otro tipo de información personal. En caso de que alguien se ponga en contacto con usted o de recibir un correo electrónico no solicitado que le pida alguno de los datos anteriores, no responda y comuníquelo inmediatamente a COQUETTE.AGENCY, para que procedan a la investigación del incidente.</p>
<br></br>
<h2>Identifique los correos electrónicos falsos (intentos de 'spoofing' o 'phishing')</h2>

<p>Ignore todo correo electrónico que, sin estar realizando ningún proceso de compra o suscripción, reciba en el que se le solicite información personal o bancaria o que le redirija a otra página web que no sea propiedad de COQUETTE.AGENCY o en el que se le pida que verifique sus datos bancarios o que pague por un medio distinto del autorizado por COQUETTE.AGENCY, ya que podría ser un intento de suplantación de personalidad ( 'spoofing' o 'phishing'), y deberá considerarlo fraudulento.</p>

<p>COQUETTE.AGENCY utiliza el dominio "https://coquette.agency". Como correo electrónico utiliza hola@coquette.agency u otros con el dominio https://coquette.agency. Si usted recibe un correo electrónico con un formato diferente, por ejemplo: [coquetteagency]@gmail.com, puede estar seguro de que es un correo falso.</p>

<p>Algunos correos electrónicos que practican phishing contienen enlaces a sitios web que utilizan en su URL la palabra del dominio que pretende suplantar, pero que le dirigirán a un sitio web completamente diferente. Si desplaza el ratón por encima del enlace, podrá ver la URL asociada, que probablemente tendrá un formato diferente a las enlazadas en los sitios web auténticos de https://coquette.agency.</p>

<p>Si aun así usted hace clic en un correo electrónico que practica phishing y es redirigido a una página que suplanta la identidad de otra con fines presumiblemente ilícitos o a cualquiera que le pida verificar o modificar su información personal, ignorela y considerela como fraudulenta.</p>

<p>En este caso, envíe un correo electrónico a las autoridades adjuntando el correo electrónico que considere falso. Al adjuntar ese correo electrónico fraudulento, está ayudando a localizar su origen. Si no puede adjuntar el correo electrónico falso, reenvielo a las autoridades e incluya la mayor cantidad de información posible sobre el mismo.</p>

<br></br>
<br></br>
<p><strong>Este aviso legal está actualizado a 31 de Octubre de 2023.</strong></p>
</Card.Body></Card>
        
    </Container>
    <Footer></Footer>
    </>
  )
}
