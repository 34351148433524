import React from 'react'
import { Container, Card } from "react-bootstrap";
import { InlineWidget } from 'react-calendly'
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/CoquetteLogo.webp';

export default function ReservaCita() {
  return (

    <Container className='mb-5 mt-5 animawap centrado '>

    <Card className='bor cardss22222 margindecita'>
    <Card.Body>
        
        <div className="centrado mt-2">
    <Link to={'/'}>   <img src={logoImg} alt='' width="100" height=""/> </Link> 
        </div>
        <h1 className='text-center hache'>RESERVA TU CITA</h1>
       
<div className="">
       
        <InlineWidget url="https://calendly.com/coquetteagetest?hide_gdpr_banner=1" />
        </div>
    </Card.Body>
  </Card>


  </Container>
  
  )
}
