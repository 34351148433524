import React, { useRef, useState } from 'react';
import { Form, Button, Card, Container, Row, Alert } from "react-bootstrap";
// import { useAuth } from '../contexts/AuthContext.js';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/CoquetteLogo.webp';

export default function Login() {

    // const emailRef = useRef()
    // const passwordRef = useRef()
    // const { login } = useAuth()
    // const [error, setError] = useState('')
    // const [loading, setLoading] = useState(false)
    // const navigate = useNavigate()

    // async function handleSubmit(e) {
    //     e.preventDefault()

       

    //     try{
    //         setError('')
    //         setLoading(true)
    //    await login(emailRef.current.value, passwordRef.current.value)
    //    navigate("/")
    //     } catch {
    //         setError('Error al iniciar sesión')
    //     }

    //     setLoading(false)
    // }

  return (
    <>
<Container className='mb-5 mt-3 animawap  vh-100 centrado '>

    <Card className='bor cardss222'>
    <Card.Body>
        <div className="centrado mt-2">
    <Link to={'/'}>   <img src={logoImg} alt='' width="100" height=""/> </Link> 
        </div>
        <h2 className="text-center mb-4 mt-1">Inicio de sesión</h2>
        {/* {error && <Alert variant='danger'>{error}</Alert>} */}
      <Form onSubmit={''}>

     
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email"  required />
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password"  required />
        </Form.Group>
        <Button disabled={'loading'} className="w-100 mt-2 btn-dark" type="submit">
              Iniciar sesión
            </Button>
      </Form>
      <div className="w100 text-center mt-4">
        <Link to="/">¿Olvidaste la contraseña?</Link>
        </div>
    </Card.Body>
  </Card>


  </Container>
{/* 
    <div className="w100 text-center mt-3">
        ¿Necesitas una cuenta? <Link to="/signup">Regístrate aquí</Link>
    </div> */}
    </>
  )
}
