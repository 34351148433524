import React, { useEffect } from 'react'
import Navbarr from '../components/Navbarr';
import Footer from '../components/Footer';
import { Container, Card, Row } from "react-bootstrap";
import dior from '../assets/rebrandLogo.webp'
import starbucks from '../assets/starbucks.webp'
import airbnb from '../assets/Airbnb.webp'
import loewe from '../assets/Loewe.webp'
import yvessaintlaurent from '../assets/YvesSaintLaurent.webp'
import instagram from '../assets/InstagramChg.webp'


export default function Rebranding() {

  useEffect(() => {
    // Esta función se ejecuta después del montaje del componente
    window.scrollTo({
      top: 30,
      behavior: 'smooth', // Agrega un desplazamiento suave
    });
  }, []);


  return (
    <>
    <Navbarr></Navbarr>

    <Container className='  alturanav'></Container>
    <Container className='text-center w-75 mt-5 mb-3'>
        <h1 className='display-2'>REBRANDING</h1>
        <p className='display-6 mb-5'>Skincare para tu negocio.</p>
</Container>

<Container className='mb-5 mt-3 animawap'>
      <Row className="justify-content-center align-items-center marcer conelmar">

          <Card className='mb-4 esca  lool2 bor cardss2 sinpad'>  
          {/* colorcard3  */}
            <Card.Body className='sinpad'>
     
         <h1 className='text-center pt-4'>LOGO</h1>
         <p className='text-center  ps-4 pe-4'>La importancia de un logo para un negocio es similar a la importancia del rostro para una persona: es la clave de la identidad y la primera impresión</p>
    {/* <p className='text-center  ps-2 pe-2'>Adaptamos actualizamos tu logo a la nueva tendencia minimalista ó creamos un concepto nuevo</p> */}
    <p className='text-center  ps-4 pe-4'>Actualizamos y adaptamos tu logo a la nueva tendencia minimalista</p>
    
    
    {/* <div className="cardabajo2">
  <div className="cintaContainer">
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={starbucks} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
  </div>
</div> */}



    <div class="logos">
      <div class="logos-slide2">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      </div>

      <div class="logos-slide2">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={loewe} width='102px' alt='logorebrand' />
      <img src={yvessaintlaurent} width='102px' alt='logorebrand' />
      </div>
    </div>

    <p className='text-center  ps-2 pe-2 mt-3'>O creamos un concepto nuevo</p>


    {/* <div className="cardabajo2">
  <div className="cintaContainer2">
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={starbucks} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
    <div className="centrado">
      <img src={dior} width='102px' alt='logorebrand' />
    </div>
  </div>
</div> */}




{/* <div class="logos">
      <div class="logos-slide">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>

      <div class="logos-slide">
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={dior} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>
    </div> */}

<div class="logos">
      <div class="logos-slide">
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>

      <div class="logos-slide">
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      <img src={instagram} width='102px' alt='logorebrand' />
      <img src={airbnb} width='102px' alt='logorebrand' />
      <img src={starbucks} width='102px' alt='logorebrand' />
      </div>
    </div>




{/* lo de obtendras y la bandfa decirlo en discurso voz medjor lo quye viene dentro  */}
    {/* <p className='text-center  ps-4 pe-4 mt-3'>Obtendrás el logotipo, isotipo y demás en distintos formatos colores banda y en la calidad mas alta (posible svg)  y distintosfondos etc</p> */}
    {/* <p className='text-center  ps-2 pe-2'>Importancia logo es simbolo y es la cara de un negocio, metafora persona</p> */}

    <p className='text-center  ps-4 pe-4 mt-3 pb-2'>El logotipo se entregará en la máxima calidad de imagen</p>

{/* equipo desarrollador diseñador grfico etc con años experiencia sector  */}
            </Card.Body>
            {/* <div className="cardabajo">
            <div className="centrado">
          <img src={dior} width='102px' alt='logorebrand' />
          </div>
          <div className="centrado">
          <img src={starbucks} width='102px' alt='logorebrand' />
          </div>
          <div className="centrado">
          <img src={dior} width='102px' alt='logorebrand' /> */}
{/* BANDA EN MOVIMIENT ODISTITNTAS MARCS REBRAN  D Y OTRA BANDA ENSEÑE DISTINTOAS FORMATOS LOGO Y HD */}
          {/* </div>
            </div> */}
          </Card>

       <Card className='mb-4 martarj lool2  esca bor cardss2 arri sinpad'>
       {/* colorcard2  */}
            <Card.Body className=''>
            <h1 className='text-center pt-2'>WEB</h1>

            <p className='text-center  ps-2 pe-2'>Olvídate de la lentitud, plugins e ineficiencias de WordPress, Shopify, Wix y similares</p>
    {/* <p className='text-center  ps-2 pe-2'>Adaptamos actualizamos tu logo a la nueva tendencia minimalista ó creamos un concepto nuevo</p> */}
    <p className='text-center  ps-2 pe-2'>Nuestro equipo profesional de desarrolladores se encargarán de diseñar y programar tu web a medida, priorizando:</p>
    <div className="divcheck ms-3 me-3">
          {/* 🎧 */}
          <p className='ms-4'><i className="fas fa-check me-2"></i>Diseño atractivo e interfaz intuitiva (UI + UX)</p>
                     <p className='ms-4'><i className="fas fa-check me-2"></i>Velocidad de carga</p> 
                     {/* NO PONER UI UX N IREPSONSIVE SINO DECIR EL BENEFICIO SOLO Y L OO TECNICISMOS POR LLAMADA  */}
                     <p className='ms-4'><i className="fas fa-check me-2"></i>Diseño adaptable a todos los dispositivos (PC, móvil, tablet, TV...) (Responsive)</p> 
                     {/* <p className='text-center'><i className="fas fa-check me-2"></i>Velocidad de carga</p>  */}
    </div>
            </Card.Body>
          </Card>
      
          <Card className='mb-4  lool2 esca bor cardss2 '>
          {/* colorcard */}
            <Card.Body>             
            <h1 className='text-center pt-2'>REDES</h1>
            <p className='text-center  ps-2 pe-2'>Renovamos la imagen de tu negocio en las principales plataformas de redes sociales (Instagram, Facebook, Youtube, TikTok)</p>
            <p className='text-center  ps-2 pe-2'> Aplicamos un enfoque estético personalizado, respaldado por técnicas probadas como el copywriting, prestando atención a cada detalle. Cada palabra importa</p>
            <p className='text-center  ps-2 pe-2'>Actualizamos la biografía y otros elementos esenciales para una presencia renovada y atractiva.</p>
            <p className='text-center'>(Este servicio debe ir estratégicamente acompañado del paquete de exposición)</p>

            </Card.Body>
          </Card>
      </Row>
    </Container>



<Footer></Footer>
    
    </>
  )
}
