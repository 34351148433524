import React from 'react'
import Navbarr from '../components/Navbarr';
import Carditas from '../components/Carditas';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';


export default function Home() {
  return (
    <>
    <Navbarr></Navbarr>
    <Hero></Hero>
      <Carditas></Carditas>
      <Footer></Footer>
      <CookiePopup></CookiePopup>
      </>
  )
}
