import React from 'react'

import logoImg from '../assets/CoquetteLogo.webp'
import youtube from '../assets/youtube.webp'
// import facebook from '../assets/facebook.png'
// import instagram from '../assets/ins.png'
import mail from '../assets/mail.webp'

export default function Footer() {
  return (
    
    <footer className="page-footer font-small blue pt-4 lool">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
                {/* <h5 className="text-uppercase mb-0">Síguenos</h5> */}
                {/* <h5 className="text-uppercase mb-0">Síguenos</h5> */}

                <a href="/"> <img className='pt-0 mb-2' src={logoImg} alt="Cuéntame Otro®" width="50" /></a>
                {/* <p className='sinmarbo'>¡Mantente actualizad@ con nosotros!</p> */}
                {/* <p className='sinmarbo'>¡Mantente actualizad@ con nosotros!</p> */}
            
                <div className="mt-2 marfo">
                <a href="" target="_blank" className="social-ico-a inline backgg"><img className="social-ico" src={youtube} alt=""/></a>

                {/* poner video yt doble mioo viral cuando piensan en moda dicen tn tan tan  y el de moquette perrette etc  y fb e ig ocultos y x hasta que haga redes  */}
{/* <a href="https://instagram.com/cuentame_otro" target="_blank" className="social-ico-a inline backgg"><img className="social-ico" src={instagram} alt=""/></a> */}
{/* <a href="https://facebook.com/TellMeAnother" target="_blank" className="social-ico-a inline backgg"><img className="social-ico" src={facebook    } alt=""/></a> */}
<a href="mailto:hola@coquette.agency" target="_blank" className="social-ico-a inline backgg"><img className="social-ico" src={mail} alt=""/></a>
                        </div>
            </div>
            <hr className="clearfix w-100 d-md-none pb-0"/>
            <div className="col-md-2 mb-md-0 mb-3">
                <h5 className="text-uppercase">Servicio</h5>
                <ul className="list-unstyled">
                    <li><a href="/reservacita">Reserva cita</a></li>
                    <li><a href="/login">Acceso a clientes</a></li>
                    <li><a href="mailto:hola@coquette.agency">hola@coquette.agency</a></li>
                </ul>
            </div>
            <div className="col-md-3 mb-md-0">
                <h5 className="text-uppercase">Términos</h5>
                <ul className="list-unstyled">
                    {/* modificar textos de saas a agencia */}
                    <li><a href="/terms">Términos & Condiciones de Uso</a></li>
                    <li><a href="/privacy">Política de Privacidad</a></li>
                    <li><a href="/cookies">Política de Cookies</a></li>
                    <li><a href="/legal">Aviso Legal</a></li>
                </ul>
            </div>
        </div>
    </div>
    <hr />
    <div className="footer-copyright text-center pb-3 sinv">© 2024 Copyright | 
        <a href="https://coquette.agency/"> Coquette.Agency</a>
    </div>
</footer>
);
};