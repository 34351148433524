import React from 'react'
import { Container, Card } from "react-bootstrap";
import Nave2 from './Navbarr';
import Footer from './Footer';

export default function Cookies

() {
  return (
   <>
    <Nave2></Nave2>
    <Container className='anteHero'></Container>
    <Container className='p-5'>
        <h1 className='text-center boldd mb-4'>Política de Cookies</h1>
        {/* POLITICAS */}
        <Card className='lool borrr'><Card.Body className='p-5'>
        <h2>Bienvenido a Coquette Agency</h2>
<p>Un espacio dedicado a ofrecer experiencias personalizadas y mejorar la navegación de nuestros visitantes. Para lograrlo, utilizamos procedimientos automáticos de recopilación de cookies, que recopilan información técnica no identificable, como el tipo de navegador, sistema operativo, página de referencia, ruta, dominio ISSP, y más. Estos datos nos ayudan a personalizar tu experiencia y mejorar nuestros servicios.</p>
<br></br>
<h2>¿Qué son las Cookies?</h2>
<p>Las cookies son pequeños archivos de texto inofensivos que se almacenan en tu navegador (como Chrome, Firefox, etc.) cuando visitas la mayoría de los sitios web. Estas cookies permiten que el sitio recuerde tu visita, facilitando la navegación y personalizando tu experiencia.</p>
<br></br>
<h2>¿Qué no es una Cookie?</h2>
<p>Las cookies no son virus, troyanos, gusanos, spam, spyware ni abren ventanas emergentes.</p>
<br></br>
<h2>¿Qué información almacena una Cookie?</h2>
<p>Las cookies no almacenan información sensible como datos bancarios o DNI. Guardan datos técnicos y preferencias, como el idioma de navegación o el tamaño de fuente.</p>
<br></br>
<h2>¿Qué tipo de Cookies existen?</h2>
<p>En función de su duración, las cookies pueden ser de sesión (expiran al cerrar el navegador) o permanentes (expiran según su propósito o se borran manualmente). Según su objetivo, se clasifican en:</p>
<br></br>
<h2>Cookies requeridas o técnicas</h2>
<p>Esenciales para el funcionamiento del sitio web y la identificación de usuarios.</p>
<br></br>
<h2>Cookies de preferencia</h2>
<p>Registran preferencias como idioma o región.</p>
<br></br>
<h2>Cookies estadísticas / analíticas</h2>
<p>Analizan la experiencia de navegación y la interacción del usuario.</p>
<br></br>
<h2>Cookies de marketing</h2>
<p>Personalizan la publicidad en función de tus preferencias.</p>
<br></br>
<h2>Cookies de geolocalización</h2>
<p>Identifican la ubicación del usuario.</p>
<br></br>
<h2>¿Qué son las Cookies propias y las de terceros?</h2>
<p>Las cookies propias son generadas por Coquette Agency, mientras que las de terceros provienen de servicios externos como Facebook, Twitter o Google.</p>
<br></br>
<h2>¿Qué ocurre si desactivo las Cookies?</h2>
<p>Desactivar las cookies puede afectar tu experiencia en línea:</p>
 <p>- No podrás compartir contenido en redes sociales.</p>
 <p>- La web no adaptará el contenido a tus preferencias.</p>
 <p>- No podrás acceder a áreas personales ni realizar compras en línea.</p>
 <p>- No podrás participar en interacciones como comentarios, valoraciones o publicaciones.</p>
<p>- La publicidad no será personalizada.</p>
<br></br>
<h2>¿Se pueden eliminar las cookies?</h2>
<p>Sí, puedes eliminar o bloquear cookies en tu navegador. Visita la sección de ayuda de tu navegador para obtener instrucciones específicas. Algunos enlaces útiles:</p>
<p>- <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">Google Chrome</a></p>
<p>- <a href="https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros">Mozilla Firefox</a></p>
<p>- <a href="http://support.apple.com/kb/ph5042">Apple Safari</a></p>
<p>- <a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</a></p>
<p>- <a href="http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></p>
<br></br>
<h2>Actualización de la Política e información de Cookies</h2>
<p>Coquette Agency se reserva el derecho de modificar esta Política de Cookies en función de las exigencias legislativas o para adaptarla a las instrucciones correspondientes.</p>
<p>Para obtener más información sobre cookies y personalizar tus opciones de activación, restricción o desactivación, consulta la sección de ayuda de tu navegador.</p>
<br></br>
{/* nameCuentameOtrono.com */}
{/* <p>El dominio https://cuentameotro.com (con el nombre CUENTAMEOTRO.COM) utiliza procedimientos automáticos de recogida de Cookies para reunir información personal, como puede ser el tipo de navegador o el sistema operativo, página de referencia, ruta, dominio ISSP, etc. Ello nos ayudan a adaptar esta página web a tus necesidades personales y favorecer una mejor experiencia de usuario.</p> */}
<p><strong>Gracias por elegir Coquette Agency como tu agencia de marketing y desarrollo.</strong></p>
</Card.Body></Card>
        
    </Container>
    <Footer></Footer>
    </>
  )
}
